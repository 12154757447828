<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<DataTable ref="dt" :value="customers" v-model:selection="selectedCustomers" selectionMode="multiple" dataKey="id" :paginator="true" :rows="20" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[20, 35, 50]"
							currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} cliente(s)" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Administre clientes</h5>
							<div id="table-header-inputs-container">
								<span id="table-search-input" class="block mt-2 md:mt-0 p-input-icon-left">
									<i class="pi pi-search" />
									<InputText v-model="filters['global'].value" placeholder="Buscar..." />
								</span>
								<span id="table-buttons-container" class="block mt-2 md:mt-0">
									<Button icon="pi pi-plus" class="p-button-rounded p-button-success mr-2" @click="openNew" />
									<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="confirmDeleteSelected" :disabled="!selectedCustomers || !selectedCustomers.length" />
									<Button icon="pi pi-download" class="p-button-rounded p-button-help mr-2" :loading="this.loadingDownloadButton" @click="downloadClients" />
								</span>
							</div>
						</div>
					</template>

					<Column field="name" header="Nombre/Razón social" :sortable="true" headerStyle="width:23.75%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Nombre/Razón social</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="identification" header="Cédula/RUC/Pasaporte" :sortable="true" headerStyle="width:23.75%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Cédula/RUC/Pasaporte</span>
							{{slotProps.data.identification}}
						</template>
					</Column>
					<Column field="email" header="Correo" :sortable="true" headerStyle="width:23.75%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Correo</span>
							{{slotProps.data.email}}
						</template>
					</Column>
                    <Column field="phone" header="Teléfono" :sortable="true" headerStyle="width:23.75%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Teléfono</span>
							{{slotProps.data.phone}}
						</template>
					</Column>
                    <Column headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editCustomer(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mt-2" @click="confirmDeleteCustomer(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="customerDialog" :style="{width: '450px'}" header="Cliente" :modal="true" class="p-fluid">
					<div class="field">
						<label for="nombres">Nombres y Apellidos</label>
						<InputText id="nombres" v-model.trim="customer.name" required="true" autofocus :class="{'p-invalid': this.nameError}" @change="checkForm()" />
						<small class="p-invalid" v-if="this.nameError">Ingrese el nombre o razón social</small>
					</div>
                    <div class="field">
						<label for="identification">Cédula/RUC/Pasaporte</label>
                        <InputText id="identification" type="text" v-model.trim="customer.identification" required="true" autofocus :class="{'p-invalid': this.identificationError}" @change="checkForm()" />
						<small class="p-invalid" v-if="this.identificationError">{{ this.identificationErrorDescription }}</small>
					</div>
                    <div class="field">
						<label for="email">Correo</label>
                        <InputText id="email" type="email" v-model.trim="customer.email" required="false" autofocus :class="{'p-invalid': submitted && !customer.name}" />
					</div>
                    <div class="field">
						<label for="phone">Teléfono</label>
                        <InputText id="phone" type="number" v-model.trim="customer.phone" required="false" autofocus :class="{'p-invalid': submitted && !customer.name}" />
					</div>
                    <div class="field">
						<label for="address">Dirección</label>
                        <InputText id="address" v-model.trim="customer.address" required="true" autofocus :class="{'p-invalid': this.addressError}" @change="checkForm()" />
						<small class="p-invalid" v-if="this.addressError">Ingrese una dirección</small>
					</div>

					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-secondary mr-2 mb-2" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-success mr-2 mb-2" @click="saveCustomer" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteCustomerDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="customer">¿Está seguro de querer borrar <b>{{customer.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-secondary mr-2 mb-2" @click="deleteCustomerDialog = false; selectedCustomers = null;"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-warning mr-2 mb-2" @click="deleteCustomer" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteCustomersDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="selectedCustomers">
							¿Está seguro de querer borrar los clientes seleccionados? <br>
							<span v-for="(customer) in selectedCustomers" :key="customer"><b>{{"- " + customer.name}}</b> <br></span>
						</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-secondary mr-2 mb-2" @click="deleteCustomersDialog = false; selectedCustomers = null;"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-warning mr-2 mb-2" @click="deleteSelectedCustomers" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import download from 'downloadjs';
import {FilterMatchMode} from 'primevue/api';
import httpRequest from '../../service/httpRequest';
import Customer from '../../models/customer';

export default {
	data() {
		return {
			customers: [],
			customerDialog: false,
			deleteCustomerDialog: false,
			deleteCustomersDialog: false,
			customer: {},
			selectedCustomers: null,
			filters: {},
			submitted: false,
			nameError: false,
			identificationError: false,
			identificationErrorDescription: null,
			addressError: false,
			loadingDownloadButton: false,
		}
	},
	created() {
        this.initFilters();
        this.getCustomers();
	},
	mounted() {
    },
	methods: {
		openNew() {
			this.customer = {};
			this.submitted = false;
			this.customerDialog = true;
		},
		hideDialog() {
			this.selectedCustomers = null;
			this.customerDialog = false;
			this.submitted = false;
		},
		checkForm() {
			var error = false;
			if (this.customer.name === null || this.customer.name === undefined || this.customer.name === "") {
				this.nameError = true;
				error = true;
			} else { this.nameError = false }
			if (this.customer.identification === null || this.customer.identification === undefined ) {
				this.identificationError = true;
				this.identificationErrorDescription = "Este campo no puede estar vacío"
				error = true;
			} else if (/^\d+$/.test(this.customer.identification) && this.customer.identification.length !== 10 && this.customer.identification.length !== 13) {
				this.identificationError = true;
				this.identificationErrorDescription = "La Cédula debe tener 10 dígitos y el RUC 13 dígitos"
				error = true;
			} else { 
				this.identificationError = false
				this.identificationErrorDescription = null;
			}
			if (this.customer.address === null || this.customer.address === undefined || this.customer.address === "") {
				this.addressError = true;
				error = true;
			} else { this.addressError = false }
			return error;
		},
		async saveCustomer() {
			this.selectedCustomers = null;
			let result = this.checkForm();
			if (!result) {
				let response = await httpRequest.saveCustomer(this.customer);
				if (response.status == 200) {
					let customer = new Customer(response.data);
					this.addCustomerToList(customer);
				} else { console.log("Error: ", response.status); }
				this.nameError = false;
				this.identificationError = false;
				this.addressError = false;
				this.hideDialog();
			}
		},
        async getCustomers() {
            let response = await httpRequest.getCustomers();
            if (response.status == 200) {
                let customers = response.data;
                this.customers = customers.map(function(data) { return new Customer(data)});
            } else { console.log("Error: ", response.status); }
        },
		editCustomer(customer) {
			this.customer = {...customer};
			this.customerDialog = true;
		},
		confirmDeleteCustomer(customer) {
			this.customer = customer;
			this.deleteCustomerDialog = true;
		},
		async deleteCustomer() {
			this.selectedCustomers = null;
            let response = await httpRequest.deleteCustomers([this.customer.id]);
            if (response.status) { 
                this.deleteCustomerFromList(this.customer);
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Cliente eliminado', life: 3000});
            } else {
                this.$toast.add({severity:'error', summary: 'Error', detail:'Hemos tenido un problema borrando el cliente', life: 3000});
            }
			this.deleteCustomerDialog = false;
		},
		confirmDeleteSelected() {
			this.deleteCustomersDialog = true;
		},
		async deleteSelectedCustomers() {
            let ids = this.selectedCustomers.map(customer => customer.id);
            let response = await httpRequest.deleteCustomers(ids);
            if (response.status) {
                for (var i=0; i<this.selectedCustomers.length; i++) {
					this.deleteCustomerFromList(this.selectedCustomers[i]); 
				}
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Cliente eliminado', life: 3000});
            } else {
                this.$toast.add({severity:'error', summary: 'Error', detail:'Hemos tenido un problema borrando los clientes seleccionados.', life: 3000});
            }
			this.selectedCustomers = null;
			this.deleteCustomersDialog = false;
        },
		async downloadClients() {
			this.loadingDownloadButton = true;
			const data = await httpRequest.downloadClients();
			download(data, "clientes" + ".xlsx", "application/vnd.ms-excel");
			this.loadingDownloadButton = false;
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
        customerIndex(customer) {
            var index = -1;
            if (customer.id === null) { index = -1; return index; }
            for (var i=0; i<this.customers.length; i++) {
                if (this.customers[i].id === customer.id) { index = i; break }
            }
            return index;
        },
        addCustomerToList(customer) {
            let index = this.customerIndex(customer);
            if (index > -1) { this.customers[index] = customer }
            else { this.customers = [customer, ...this.customers] } 
        },
        deleteCustomerFromList(customer) {
            let index = this.customerIndex(customer);
            this.customers.splice(index, 1);
            this.customer = null;
            if (this.customers.length == 0) { this.customers = [] }
        }
	}
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';
@media screen and (max-width: 769px) {
	#table-header-inputs-container {
		display: block;
	}
	#table-search-input {
		margin: 15px 0px !important;
	}
}
@media screen and (min-width: 769px) {
	#table-header-inputs-container {
		display: flex;
	}
	#table-search-input {
		margin: 0px 15px;
	}
}
</style>
